/** Add the event listener to the button.
 *
 * Create an ajax request to the server to delete the listing
 * If the request is successful, remove the listing from the DOM and show a message
 * If the request fails, show an error message.
 */

export function handleDeleteListing() {
	if (typeof iugoFindAMemberExtensions == 'undefined') {
		return;
	}

	if (!iugoFindAMemberExtensions.ajaxUrl || !iugoFindAMemberExtensions.nonce) {
		return;
	}

	const $deleteListingButton = jQuery('.fam-delete-listing');

	if ($deleteListingButton.length === 0) {
		return;
	}

	$deleteListingButton.on('click', function (e) {
		e.preventDefault();

		// Get the listing ID from the data attribute.
		const listingId = jQuery(this).data('listing-id');

		if (!confirm('Are you sure you want to delete this listing?')) {
			return;
		}
		// Send the request to the server to delete the listing.
		jQuery.ajax({
			url: iugoFindAMemberExtensions.ajaxUrl,
			method: 'POST',
			data: {
				action: 'find_a_member_extensions_delete_listing',
				listing_id: listingId,
				_wpnonce: iugoFindAMemberExtensions.nonce,
			},
		}).done(function (response) {
			// If the request is successful, remove the listing from the DOM.
			if (response.success) {
				$deleteListingButton.closest('.fam-listing').remove();

				// Refresh the page.
				location.reload();
			} else {
				alert('There was an error deleting the listing.');
				console.error(response);
			}
		}).fail(function () {
			alert('There was an error deleting the listing.');
		});
	});
}
